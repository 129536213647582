
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

import 'dayjs/locale/de'
import 'dayjs/locale/pl'
import 'dayjs/locale/cs'
import 'dayjs/locale/sk'
import 'dayjs/locale/hu'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import isBetween from 'dayjs/plugin/isBetween'
import duration from 'dayjs/plugin/duration'


dayjs.extend(updateLocale)

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isBetween)
dayjs.extend(duration)

dayjs.tz.setDefault('Europe/Prague')

// defaultLocale: undefined



export default dayjs
