<template>
    <Teleport to="body">
        <div class="toast-container mobile-padding-top position-absolute w-100 end-0 top-0 ps-3 pe-3 pb-3">
            <div
                v-for="toastArrayItem in store.toastNotifications"
                :id="toastArrayItem.id"
                :key="toastArrayItem.id"
                class="toast align-items-center w-100"
                :class="`text-bg-${toastArrayItem.type}`"
                role="alert"
                aria-live="assertive"
                aria-atomic="true"
                :data-bs-delay="toastArrayItem.duration"
            >
                <div class="d-flex">
                    <div class="toast-body text-light fw-bold">{{ toastArrayItem.message }}</div>
                    <button
                        type="button"
                        class="btn-close btn-close-white m-auto me-2"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                    />
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
const { $bootstrap } = useNuxtApp();

const activeToastNotifications = ref<string[]>([]);

const store = useStore();

const showToast = (toastId: string) => {
    nextTick().then(() => {
        const toastElement = document.getElementById(toastId);
        if (toastElement) {
            const toast = new $bootstrap.Toast(toastElement);
            toast.show();
            toastElement.addEventListener("hidden.bs.toast", () => {
                toast.dispose();
                activeToastNotifications.value = activeToastNotifications.value.filter(
                    (predicate) => predicate !== toastId
                );
                store.removeToastNotification(toastId);
            });
        }
    });
};

watch(
    () => store.toastNotifications,
    (newValue) => {
        // filter out old values and save it to new constant
        const newValues = newValue.filter((predicate) => !activeToastNotifications.value.includes(predicate.id));
        newValues.forEach((value) => {
            showToast(value.id);
            activeToastNotifications.value.push(value.id);
        });
    },
    { deep: true }
);
</script>
