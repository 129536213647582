import { defineStore } from "pinia";
import type { ITrip, ITripWithLocation } from "~/../backend/types/trip";
import { useConnectionStore } from "~/stores/connection";
import type { ILegislative, ITerminal } from "~/types/terminal";
import type { INuxtStore, IToastNotificationType } from "~/types/nuxt";

import { useAxios } from "~/composables/useAxios";

export const useStore = defineStore({
    id: "mainStore",

    state: (): INuxtStore => ({
        loading: false,
        pageLoading: false,
        appInitFailed: false,
        activeRide: null,
        connectingRide: undefined,
        activeRideReady: false,
        terminals: [],
        terminal: null,
        newMessagesCount: 0,
        rideDetail: null,
        rideDetailsData: [],
        appVersion: (useRuntimeConfig().public.packageVersion as string) || "",
        legislatives: [],
        toastNotifications: []
    }),

    actions: {
        addToastNotification(message: string, type: IToastNotificationType, duration: number) {
            this.toastNotifications.push({
                message,
                type,
                id: `toast-${Math.floor(Math.random() * 100000).toString()}`,
                duration
            });
        },
        removeToastNotification(id: string) {
            this.toastNotifications = this.toastNotifications.filter(
                (notification: IToastNotification) => notification.id !== id
            );
        },
        setVersion(value: string) {
            this.appVersion = value;
        },

        setLoading(value: boolean) {
            this.loading = value;
        },
        setActiveRide(value: ITrip | null) {
            this.activeRide = value;
            this.activeRideReady = true;
        },

        setConnectingRide(value: ITrip | null) {
            this.connectingRide = value;
        },

        setTerminals(value: Array<ITerminal>) {
            this.terminals = value;
        },

        async loadTerminals() {
            const { data: terminals } = await useAxios().get<Array<ITerminal>>("api/terminals");
            if (terminals) {
                this.setTerminals(terminals);
            }
        },

        setActiveTerminal(value: ITerminal | null) {
            this.terminal = value;
        },

        setNewMessagesCount(value: number) {
            this.newMessagesCount = value;
        },

        decrementNewMessagesCount(value: number) {
            this.newMessagesCount = this.newMessagesCount - value;
        },

        setRideDetail(ride: Array<ITripWithLocation> | ITrip | null) {
            if (ride) {
                if (Array.isArray(ride)) {
                    this.rideDetailsData = ride;
                } else {
                    this.rideDetail = ride.ID_DRIVER;
                    this.rideDetailData = ride;
                }
            } else {
                this.rideDetail = null;
                this.rideDetailData = undefined;
            }
        },

        pushLegislative(value: ILegislative) {
            this.legislatives.push(value);
        },

        clearLegislatives() {
            this.legislatives = [];
        },

        clearActiveTrip() {
            this.activeRide = null;
            this.connectingRide = undefined;
            this.activeRideReady = false;
        },

        clearActiveRide() {
            this.clearActiveTrip();
            this.setActiveRide(null);
            this.setActiveTerminal(null);
        },

        setLegislativeShow(value: { index: number; isShown: boolean }) {
            this.legislatives[value.index].show = value.isShown;
        },

        setLegislativeCheck(value: { index: number; isChecked: boolean }) {
            this.legislatives[value.index].check = value.isChecked;
        },
        async loadActiveRide(skipSocketSend = false) {
            // replace 'any' with the actual type
            this.setLoading(true);
            try {
                const { data: result } = await useAxios().get<{ ride: ITrip; connectingRide: ITrip }>("api/ride/me");
                const connectionStore = useConnectionStore();
                this.setActiveRide(result.ride);
                this.setConnectingRide(result.connectingRide);
                if (!skipSocketSend) {
                    connectionStore.socket.emit("ride.update", this.activeRide);
                }

                this.setLoading(false);
            } catch (e) {
                if ("caches" in window) {
                    const cacheNames = await caches.keys();
                    await Promise.all(
                        cacheNames.map(async (cacheName) => {
                            await caches.delete(cacheName);
                        })
                    );
                }

                location.reload();
            }
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
}
