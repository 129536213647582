export default defineNuxtRouteMiddleware((to) => {
    const userCan = useCan();
    const { data: authData, status: authStatus } = useAuth();
    const connectionStore = useConnectionStore();
    if (to.name === "auth-logout") {
        return;
    } else if (
        authStatus.value === "unauthenticated" &&
        (to.meta.layout === "dispatcher" || to.meta.layout === "driver")
    ) {
        return navigateTo({ name: "auth-login" });
    }
    if (authData.value) {
        connectionStore.connect();
        connectionStore.bindEvents();
    }
    if (authData.value && !userCan.viewDispo && (to.meta.layout === "dispatcher" || to.meta.layout === undefined)) {
        return navigateTo({ name: "index" });
    } else if (authData.value && !userCan.viewDispo && userCan.viewWaypoints && to.name !== "dispatcher-waypoints") {
        return navigateTo({ name: "dispatcher-waypoints" });
    } else if (
        authData.value &&
        (userCan.viewDispo || authData.value.rights?.includes("admin")) &&
        (to.meta.layout === "driver" || to.meta.layout === undefined)
    ) {
        return navigateTo({ name: "dispatcher" });
    }
});
