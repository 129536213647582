<template>
    <div class="h-screen">
        <div class="page-loader" :class="[{ 'not-active': !store.pageLoading && !store.appInitFailed }]">
            <div class="page-loader-background" />
            <div class="page-loader-spinner" />
        </div>
        <CommonToastComponent />
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script setup lang="ts">
import "@popperjs/core";

const nuxtApp = useNuxtApp();
const store = useStore();

nuxtApp.hook("page:start", () => {
    store.pageLoading = true;
});
nuxtApp.hook("page:loading:start", () => {
    store.pageLoading = true;
});

nuxtApp.hook("page:finish", () => {
    store.pageLoading = false;
});
</script>
<style lang="scss">
.page-loader-spinner {
    display: block;
    position: fixed;
    z-index: 1089;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate(-50%, -50%);
    width: 8rem;
    height: 8rem;
    box-sizing: border-box;
    border: solid 1rem transparent;
    border-top-color: #008cff;
    border-left-color: #008cff;
    border-bottom-color: #efefef77;
    border-right-color: #efefef77;
    border-radius: 50%;
    -webkit-animation: loader 1000ms linear infinite;
    animation: loader 1000ms linear infinite;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.page-loader {
    display: inline-block;
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: visible;
    transition:
        opacity 0.4s ease-in-out,
        visibility 0.4s ease-in-out;
}

.page-loader.not-active {
    opacity: 0;
    visibility: hidden;
}

// .lds-dual-ring {
//     display: inline-block;
//     width: 80px;
//     height: 80px;
//     position: fixed;
//     z-index: 2000;
//     height: 4em;
//     width: 4em;
//     overflow: show;
//     margin: auto;
//     top: 0;
//     left: 0;
//     bottom: 4em;
//     right: 4em;
// }

// .lds-dual-ring .spinner {
//     box-sizing: border-box;
//     display: block;
//     width: 8em;
//     height: 8em;
//     border-radius: 50%;
//     border: 1em solid red;
//     border-color: red transparent red transparent;
//     animation: lds-dual-ring 1.2s linear infinite;
//     opacity: 0;
// }

// @keyframes lds-dual-ring {
//     0% {
//         transform: rotate(0deg);
//     }
//     100% {
//         transform: rotate(360deg);
//     }
// }
// .page-loader.active {
//     display: none;
// }

.page-loader-background {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: black;
    background: radial-gradient(rgba(20, 20, 20, 0.349), rgba(0, 0, 0, 0.349));
    background: -webkit-radial-gradient(rgba(20, 20, 20, 0.349), rgba(0, 0, 0, 0.349));
}
</style>
