import axios from "axios";

export function useAxios() {
    const config = useRuntimeConfig();
    const { token } = useAuthState();
    const instance = axios.create({
        baseURL: config.public.baseUrl === "" ? "/" : (config.public.baseUrl as string),
        headers: {
            Authorization: token.value ? token.value : ""
        }
    });

    return instance;
}
