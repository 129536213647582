import { defineStore } from "pinia";
import { io, type Socket } from "socket.io-client";

interface State {
    socket: Socket;
}

interface _UserMessageObject {
    ID: number;
    USER?: string;
    FULLNAME?: string;
    EMAIL?: string;
    DESCRIPTION?: string;
    LANGUAGE: "cs" | "sk" | "pl" | "de" | "hu" | "en";
    USERRIGHT?: string;
    ID_RIDICI?: number;
    RIDE_ID?: number;
    DELETED_ON?: string;
    TERMINAL?: string;
}

export const useConnectionStore = defineStore("connection", {
    state: (): State => ({
        socket: io(useRuntimeConfig().public.baseUrl as string, {
            withCredentials: true,
            autoConnect: false,
            path: "/api/socket.io",
            extraHeaders: {
                "Access-Control-Allow-Credentials": "true"
            },
            transports: ["websocket"]
        })
    }),

    actions: {
        bindEvents() {
            this.socket.on("disconnect", () => {
                this.socket.auth = {};
                this.socket.off();
            });

            this.socket.io.on("reconnect_attempt", (_attempt) => {
                this.socket.auth = {
                    token: useAuthState().token.value // Update the auth object
                };
            });
        },

        chatMessageEvent(callback: (data: { sender: { id: number; user: string }; message: string }) => void) {
            this.socket.on("chat.message", callback);
        },

        connect() {
            this.socket.auth = {
                token: useAuthState().token.value // Update the auth object
            };
            this.socket.connect();
        },
        disconnect() {
            this.socket.disconnect();
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useConnectionStore, import.meta.hot));
}
